<!--
File: GroupEditForm.vue
Description: form for adding/editing a single DEU.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field :class="getClass('name_en')">
          <label :for="name_en">{{ $t('stdCols.name_en') }}</label>
          <md-input id="name_en" v-model="name_en" type="text" data-vv-name="name_en" required
            v-validate="modelValidations.name_en"></md-input>
        </md-field>

        <md-field :class="getClass('name_ru')">
          <label :for="name_ru">{{ $t('stdCols.name_ru') }}</label>
          <md-input id="name_ru" v-model="name_ru" type="text" data-vv-name="name_ru" required
            v-validate="modelValidations.name_ru"></md-input>
        </md-field>

        <md-field :class="getClass('name_kg')">
          <label :for="name_kg">{{ $t('stdCols.name_kg') }}</label>
          <md-input id="name_kg" v-model="name_kg" type="text" data-vv-name="name_kg" required
            v-validate="modelValidations.name_kg"></md-input>
        </md-field>

        <BaseDropdown :class="getClass('region_id')" :label="$t('road_network.region')" v-model="region_id"
          data-vv-name="region_id" v-validate="modelValidations.region_id" :items="groupRegions"
          :displayField="displayField" :isRequired="true" />

        <BaseDropdown :class="getClass('access_level')" :label="$t('groups.access_level')" v-model="access_level"
          data-vv-name="access_level" v-validate="modelValidations.access_level" :items="accessLevels"
          :isRequired="true" />
      </div>
    </template>
  </modal>
</template>
<script>
import { mapActions } from 'vuex'
import { Modal, RegionsDropdown, BaseDropdown } from '@/pages/Components'
import { savedMessage } from '@/mixins/messagesMixin'
import { getLang } from '@/api'
import permissions from "@/mixins/permissionsMixin"

export default {
  name: 'group-edit-form',
  mixins: [permissions],

  data() {
    return {
      formName: 'GroupEditForm',
      eligible: false,

      name_en: null,
      name_ru: null,
      name_kg: null,
      region_id: null,
      access_level: null,
      accessLevelList: [],
      groupRegions: [],

      initialFormState: null,
      modelValidations: {
        name_en: { required: true, min: 3 },
        name_ru: { required: true, min: 3 },
        name_kg: { required: true, min: 3 },
        region_id: { required: true, numeric: true, min_value: 1 },
        access_level: { required: true, numeric: true, min_value: 0 },
      }
    }
  },

  props: {
    itemId: null
  },

  components: {
    Modal,
    RegionsDropdown,
    BaseDropdown
  },

  async mounted() {
    // Check if we are eligible to view the form
    this.eligible = await this.checkIfScreenAllowed()
    if (!this.eligible) {
      this.$emit('close')
      return
    };

    if (this.itemId) { // Load data on existing group
      const theGroup = await this.loadGroup(this.itemId)
      this.name_en = theGroup.name_en
      this.name_ru = theGroup.name_ru
      this.name_kg = theGroup.name_kg
      this.region_id = theGroup.region_id
      this.access_level = theGroup.access_level
    }
    this.$nextTick(async () => {
      this.initialFormState = this.getCurrentState;
      await this.$validator.validateAll();
    });
    this.accessLevelList = await this.loadAccLevels();
    this.groupRegions = await this.loadRegionsList();
    console.log(this.groupRegions)
  },

  methods: {
    ...mapActions({
      loadGroup: 'ReferenceData/LOAD_GROUP_BY_ID',
      loadAccLevels: 'ReferenceData/LOAD_ACC_LEVEL_LIST',
      loadRegionsList: 'ReferenceData/LOAD_GROUPS_REGION_LIST',
      addItem: 'ReferenceData/ADD_NEW_GROUP',
      editItem: 'ReferenceData/UPDATE_GROUP',
      highlightRow: 'HIGHLIGHT_ROW'
    }),

    async validate() {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return

      const theItem = this.getCurrentState;
      let errDesc = '';
      let newitemId;
      const action = !this.itemId ? this.addItem : this.editItem;
      const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
      try {
        const res = await action(payload)
        newitemId = res?.id;
      } catch (err) {
        errDesc = err.message || this.$t('messages.unknown_error')
      }

      this.$nextTick(() => this.$validator.reset())
      this.$emit('close')
      await savedMessage(errDesc, this.$t('users.group'), this.name_en, this.name_ru,)
      this.highlightRow(newitemId)
    },
  },

  computed: {
    getCurrentState() {
      return {
        name_en: this.name_en,
        name_ru: this.name_ru,
        name_kg: this.name_kg,
        access_level: +this.access_level,
        region_id: +this.region_id
      };
    },

    hasUnsavedChanges() {
      if (!this.initialFormState) return false

      // TODO: delete the formatting after Andrey resolves it in the back-end or move to the api side
      return this.initialFormState.name_en !== this.name_en ||
        this.initialFormState.name_ru !== this.name_ru ||
        this.initialFormState.name_kg !== this.name_kg ||
        this.initialFormState.access_level !== this.access_level ||
        this.initialFormState.region_id !== this.region_id
    },

    screenTitle() {
      return this.itemId ? this.$t('screen_titles.group_upd') : this.$t('screen_titles.group_add')
    },

    displayField() {
      return `name_${getLang()}`;
    },

    accessLevels() {
      return this.accessLevelList.map(item => ({
        id: item.id,
        description: item[this.displayField],
      }))
    }
  }
}
</script>